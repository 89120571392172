import { gql } from "@apollo/client";

// export const LOGIN_USER = gql``;

export const GET_SUBJECTS_QUERY = gql`
  query {
    subjects {
      id
      companyName
      companyStatus
      paymentStatus
      debt2011To2023
      totalDebt2024
      businessType
      nipt
      phone
      amountToPay
      totalAmount
      companyTaxes {
        id
        tax {
          name
        }
      }
    }
  }
`;

export const GET_TAXES_QUERY = gql`
  query {
    taxes(getTaxes: { userId: null }) {
      id
      name
    }
  }
`;


export const GET_USERS_QUERY = gql`
  query {
    users(getUsers: { userId: null }) {
      id
      firstName
      lastName
      email
      phone
      birthday
      updatedBy
      updatedOnUtc
      deletedOnUtc
      deletedBy
      permission
      isDeleted
      id
      privileges {
        access
        privilege {
          id
          type
        }
      }
    }
  }
`;

export const GET_CATEGORIES_QUERY = gql`
  query {
    categories(request: { userId: null }) {
      id
      categoryName
    }
  }
`;

export const PRIVILEGES_QUERY = gql`
  query {
    allPrivileges(request: { userId: null }) {
      id
      type
    }
  }
`;

export const DASHBOARD_DATA = gql`
  query {
    dashboard(request: {}) {
      key
      value
    }
    annualRevenues(request: {}) {
      key
      value
    }
  }
`;

export const RAPORT_DATA = gql`
  query ($period: String!) {
    generateRaport(request: { raportPeriod: $period }) {
      period
      totalPaid
      totalDebt
      totalTransactions
      averagePaymentTimeDays
      totalToBePaid
    }
  }
`;

export const ENTITY_BY_ID_QUERY = gql`
  query ($in: GetSubjectByIdInput!) {
    companyById(request: $in) {
      companyName
      id
      nipt
      phone
      companyStatus
      debt2011To2023
      totalDebt2024
      businessType
      paymentStatus
      administratorName
      activity
      address
      businessType
      taxCodification
      bills {
        numberOfBIll
        value
        billSTatus
        end
        debt
      }
      comments {
        comment
        from
        to
        createdOnUtc
      }
    }
  }
`;

export const ENTITY_TAXES_BY_ID = gql`
  query ($CompanyId: UUID!) {
    generateTaxes(request: { companyId: $CompanyId, userId: null }) {
      taxName
      valueString
    }
  }
`;

export const GET_BILLS_QUERY = gql`
  query ($companyId: UUID!) {
    companyBills(request: { companyId: $companyId, userId: null }) {
      id
      debt
      period
      value
      companyName
      remaining
      paid
      billSTatus
      numberOfBIll
    }
  }
`;

export const GET_BILL_BY_ID_QUERY = gql`
  query ($billId: UUID!) {
    billById(request: { billId: $billId }) {
      id
      valueString
      debt
      companyName
      period
      nipt
      numberOfBIll
      taxCodification
      start
      nipt
      remaining
      paid
      taxesList {
        taxName
        valueString
      }
    }
  }
`;

export const GET_ALL_TICKETS = gql`
  query {
    allTickets {
      id
      serialNumber
      date
      firstName
      lastName
      value
      lateInterest
      paid
      debt
      isPaid
    }
  }
`;

export const GET_TICKET_BY_ID = gql`
  query ($ticketId: UUID!) {
    allTickets(request: { ticketId: $ticketId }) {
      id
      serialNumber
      date
      firstName
      lastName
      value
      lateInterest
      paid
      debt
      isPaid
    }
  }
`;

export const GET_ALL_DEBTS = gql`
query {
  debt(request: {}) {
    firstName
    lastName
    subjectName
    gj311222
    shtesa23
    pak23
    mbetja23
    unit
    bussinesType
    id
  }
}
`;
