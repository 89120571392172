import { useQuery } from "@apollo/client";
import { BarChart } from "@tremor/react";
import { ComponentType, useCallback } from "react";
import { Card } from "../../components/ui/card";
import { DASHBOARD_DATA, RAPORT_DATA } from "../../graphql/Queries";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import { ArrowDownToLine, Eye } from "lucide-react";
import { Button } from "../../components/ui/button";
import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "../../components/ui/alert-dialog";
import RaportJavor from "./RaportJavor";
import RaportMujor from "./RaportMujor";
import RaportVjetor from "./RaportVjetor";

const Months = [
  {
    key: "Jan",
    value: "Janar",
  },
  {
    key: "Feb",
    value: "Shkurt",
  },
  {
    key: "Mar",
    value: "Mars",
  },
  {
    key: "Apr",
    value: "Prill",
  },
  {
    key: "May",
    value: "Maj",
  },
  {
    key: "Jun",
    value: "Qershor",
  },
  {
    key: "Jul",
    value: "Korrik",
  },
  {
    key: "Aug",
    value: "Gusht",
  },
  {
    key: "Sep",
    value: "Shtator",
  },
  {
    key: "Oct",
    value: "Tetor",
  },
  {
    key: "Nov",
    value: "Nëntor",
  },
  {
    key: "Dec",
    value: "Dhjetor",
  },
];

const valueFormatter = (number: number) =>
  `${new Intl.NumberFormat("us").format(number).toString()} Lekë`;

const tooltip: ComponentType<any> = ({ payload, active }) => {
  if (!active || !payload) return null;
  return (
    <div className="w-56 bg-white rounded-tremor-default text-tremor-default bg-tremor-background p-2 shadow-tremor-dropdown border border-tremor-border">
      {payload?.map((category: any, idx: number) => (
        <div key={idx} className="flex flex-1 space-x-2.5">
          <div
            className={`w-1 flex flex-col bg-${category.color}-500 rounded`}
          />
          <div className="space-y-1">
            <p className="text-tremor-content">{category.dataKey}</p>
            <p className="font-medium text-tremor-content-emphasis">
              {category?.value} Lekë
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};

const DashboardPageComponent = () => {
  const { data } = useQuery(DASHBOARD_DATA, {
    fetchPolicy: "cache-and-network",
  });
  // const { data: weeklyData } = useQuery(RAPORT_DATA, {
  //   fetchPolicy: "cache-and-network",
  //   variables: {
  //     period: "JAVOR",
  //   },
  // });
  // const { data: monthlyData } = useQuery(RAPORT_DATA, {
  //   fetchPolicy: "cache-and-network",
  //   variables: {
  //     period: "MUJOR",
  //   },
  // });
  // const { data: yearlyData } = useQuery(RAPORT_DATA, {
  //   fetchPolicy: "cache-and-network",
  //   variables: {
  //     period: "VJETOR",
  //   },
  // });

  const getNameOfMothByKey = useCallback((key: string) => {
    const monthName = Months.filter((month: any, idx) => month.key === key);
    return monthName[0].value;
  }, []);

  return (
    <div className="w-full">
      <h1 className="text-4xl font-bold text-amber-950">Paneli qendror</h1>
      <div className="mt-8 flex flex-col gap-2">
        <h6 className="text-lg font-semibold text-amber-950">Statistika</h6>
        {data?.dashboard && (
          <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-4">
            <Card className="p-6 border-amber-300 flex flex-col justify-between gap-3">
              <h6 className="text-sm font-medium text-amber-950">
                Të ardhurat totale
              </h6>
              <p className="font-bold text-2xl text-amber-950">
                {data?.dashboard[0].value} Lekë
              </p>
              {/* <p className="text-sm text-amber-950">+20% nga muaji i fundit</p> */}
            </Card>
            <Card className="p-6 border-amber-300 flex flex-col justify-between gap-3">
              <h6 className="text-sm font-medium text-amber-950">
                Të ardhurat këtë muaj
              </h6>
              <p className="font-bold text-2xl text-amber-950">
                {data?.dashboard[1].value} Lekë
              </p>
              {/* <p className="text-sm text-amber-950">90% e muajit të fundit</p> */}
            </Card>
            <Card className="p-6 border-amber-300 flex flex-col justify-between gap-3">
              <h6 className="text-sm font-medium text-amber-950">
                Subjektet që kanë kryer pagesat këtë muaj{" "}
              </h6>
              <p className="font-bold text-2xl text-amber-950">
                {data?.dashboard[2].value} nga {data?.dashboard[3].value}{" "}
                <span className="text-base">subjekte aktive</span>
              </p>
              {/* <p className="text-base text-amber-950">
              485/500 (97%) në muajin e fundit
            </p> */}
            </Card>
            <Card className="p-6 border-amber-300 flex flex-col justify-between gap-3">
              <h6 className="text-sm font-medium text-amber-950">
                Subjekte aktive
              </h6>
              <p className="font-bold text-2xl text-amber-950">
                {data?.dashboard[3].value}
              </p>
              {/* <p className="text-sm text-amber-950">700 subjekte në total</p> */}
            </Card>
          </div>
        )}
        {data?.annualRevenues && (
          <div className="mt-8 w-full max-w-full">
            <Card className="p-6 w-full bg-white border-amber-300">
              <h6 className="text-base font-medium text-amber-950">
                Të ardhurat sipas muajve
              </h6>
              <BarChart
                className="mt-6 w-full"
                data={data?.annualRevenues.map((item: any, idx: number) => {
                  return {
                    name: getNameOfMothByKey(item.key),
                    "Të ardhurat": item.value,
                  };
                })}
                customTooltip={tooltip}
                index="name"
                categories={["Të ardhurat"]}
                colors={["amber"]}
                valueFormatter={valueFormatter}
                yAxisWidth={48}
              />
            </Card>
          </div>
        )}
      </div>
      {/* <div className="mt-8 flex flex-col gap-2">
        <h6 className="text-lg font-semibold text-amber-950">
          Gjenerimi i raporteve
        </h6>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <Card className="p-6 border-amber-300 flex flex-col justify-between gap-3">
            <h6 className="text-sm font-medium text-amber-950">Raport javor</h6>
            <div className="flex gap-2">
              <PDFDownloadLink
                document={<RaportJavor data={weeklyData} />}
                fileName="Raport Javor"
              >
                <Button size="sm" variant="outline" className="w-full">
                  <ArrowDownToLine className="h-4 w-4 mr-1" />
                  <span>Shkarko raportin</span>
                </Button>
              </PDFDownloadLink>
              <AlertDialog>
                <AlertDialogTrigger asChild>
                  <Button
                    size="sm"
                    variant="default"
                    className="bg-amber-300 hover:bg-amber-200 w-full"
                  >
                    <Eye className="h-4 w-4 text-amber-950 mr-1" />
                    <span className="text-amber-950">Shiko raportin</span>
                  </Button>
                </AlertDialogTrigger>
                <AlertDialogContent className="min-w-[80svw] min-h-[90svh] flex flex-col">
                  <AlertDialogHeader className="flex-none">
                    <AlertDialogTitle>Raport Javor</AlertDialogTitle>
                    <AlertDialogDescription className="flex flex-col gap-1"></AlertDialogDescription>
                  </AlertDialogHeader>
                  <PDFViewer className="w-full flex-1">
                    <RaportJavor data={weeklyData} />
                  </PDFViewer>
                  <AlertDialogFooter>
                    <AlertDialogCancel>Mbyll</AlertDialogCancel>
                  </AlertDialogFooter>
                </AlertDialogContent>
              </AlertDialog>
            </div>
          </Card>
          <Card className="p-6 border-amber-300 flex flex-col justify-between gap-3">
            <h6 className="text-sm font-medium text-amber-950">Raport mujor</h6>
            <div className="flex gap-2">
              <PDFDownloadLink
                document={<RaportMujor data={monthlyData} />}
                fileName="Raport Mujor"
              >
                <Button size="sm" variant="outline" className="w-full">
                  <ArrowDownToLine className="h-4 w-4 mr-1" />
                  <span>Shkarko raportin</span>
                </Button>
              </PDFDownloadLink>
              <AlertDialog>
                <AlertDialogTrigger asChild>
                  <Button
                    size="sm"
                    variant="default"
                    className="bg-amber-300 hover:bg-amber-200 w-full"
                  >
                    <Eye className="h-4 w-4 text-amber-950 mr-1" />
                    <span className="text-amber-950">Shiko raportin</span>
                  </Button>
                </AlertDialogTrigger>
                <AlertDialogContent className="min-w-[80svw] min-h-[90svh] flex flex-col">
                  <AlertDialogHeader className="flex-none">
                    <AlertDialogTitle>Raport mujor</AlertDialogTitle>
                    <AlertDialogDescription className="flex flex-col gap-1"></AlertDialogDescription>
                  </AlertDialogHeader>
                  <PDFViewer className="w-full flex-1">
                    <RaportMujor data={monthlyData} />
                  </PDFViewer>
                  <AlertDialogFooter>
                    <AlertDialogCancel>Mbyll</AlertDialogCancel>
                  </AlertDialogFooter>
                </AlertDialogContent>
              </AlertDialog>
            </div>
          </Card>
          <Card className="p-6 border-amber-300 flex flex-col justify-between gap-3">
            <h6 className="text-sm font-medium text-amber-950">
              Raport vjetor
            </h6>
            <div className="flex gap-2">
              <PDFDownloadLink
                document={<RaportVjetor data={yearlyData} />}
                fileName="Raport Vjetor"
              >
                <Button size="sm" variant="outline" className="w-full">
                  <ArrowDownToLine className="h-4 w-4 mr-1" />
                  <span>Shkarko raportin</span>
                </Button>
              </PDFDownloadLink>
              <AlertDialog>
                <AlertDialogTrigger asChild>
                  <Button
                    size="sm"
                    variant="default"
                    className="bg-amber-300 hover:bg-amber-200 w-full"
                  >
                    <Eye className="h-4 w-4 text-amber-950 mr-1" />
                    <span className="text-amber-950">Shiko raportin</span>
                  </Button>
                </AlertDialogTrigger>
                <AlertDialogContent className="min-w-[80svw] min-h-[90svh] flex flex-col">
                  <AlertDialogHeader className="flex-none">
                    <AlertDialogTitle>Raport vjetor</AlertDialogTitle>
                    <AlertDialogDescription className="flex flex-col gap-1"></AlertDialogDescription>
                  </AlertDialogHeader>
                  <PDFViewer className="w-full flex-1">
                    <RaportVjetor data={yearlyData} />
                  </PDFViewer>
                  <AlertDialogFooter>
                    <AlertDialogCancel>Mbyll</AlertDialogCancel>
                  </AlertDialogFooter>
                </AlertDialogContent>
              </AlertDialog>
            </div>
          </Card>
        </div>
      </div> */}
    </div>
  );
};

export default DashboardPageComponent;
