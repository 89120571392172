import { ApolloClient, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { createUploadLink } from "apollo-upload-client";

const httpLink = createUploadLink({
  uri: "https://cointmaster-mat.azurewebsites.net/graphql/", //DEMO
  // GRAMSHI // uri: "https://coinmastergramsh.blackbay-7d470aa4.germanywestcentral.azurecontainerapps.io/graphql/",
  // MAT // uri: "https://coinmaster.azurewebsites.net/graphql/",
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem("token");
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : null,
    },
  };
});

export const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});
