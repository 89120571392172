import { Route, Routes } from "react-router-dom";
import ProtectedRoute from "./components/PrivateRoute";
import useUser from "./hooks/useUser";
import AuthPage from "./pages/Auth";
import DashboardPage from "./pages/Dashboard/";
import EntitiesPage from "./pages/Entities/";
import EntityPage from "./pages/Entity/";
import NotFoundPage from "./pages/NotFound";
import SystemUsersPage from "./pages/SystemUsers/";
import TaxesPage from "./pages/Taxes/";
import { getUserRole } from "./lib/getUserRole";
import TicketsPage from "./pages/Tickets";
import RaportsPage from "./pages/Raports";
import DebtsPage from "./pages/Debts";

const App = () => {
  const { user, loading } = useUser();

  return (
    <>
      {!loading && (
        <Routes>
          <Route
            path="/"
            index
            element={
              user ? (
                <ProtectedRoute isAllowed={!!user} redirectPath="/">
                  <DashboardPage />
                </ProtectedRoute>
              ) : (
                <AuthPage />
              )
            }
          />
          <Route
            path="raports"
            element={
              <ProtectedRoute isAllowed={!!user} redirectPath="/">
                <RaportsPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="subjektet"
            element={
              <ProtectedRoute isAllowed={!!user} redirectPath="/">
                <EntitiesPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="subjekti"
            element={
              <ProtectedRoute isAllowed={!!user} redirectPath="/">
                <EntityPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="taksat"
            element={
              <ProtectedRoute isAllowed={!!user} redirectPath="/">
                <TaxesPage />
              </ProtectedRoute>
            }
          />
          {getUserRole() === "SUPERADMIN" && (
            <Route
              path="perdoruesit"
              element={
                <ProtectedRoute isAllowed={!!user} redirectPath="/">
                  <SystemUsersPage />
                </ProtectedRoute>
              }
            />
          )}
          <Route
            path="gjobat"
            element={
              <ProtectedRoute isAllowed={!!user} redirectPath="/">
                <TicketsPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="debitoret"
            element={
              <ProtectedRoute isAllowed={!!user} redirectPath="/">
                <DebtsPage />
              </ProtectedRoute>
            }
          />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      )}
    </>
  );
};

export default App;
