import { useMutation } from "@apollo/client";
import {
  ColumnDef,
  ColumnFiltersState,
  SortingState,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { ChevronDown, MoreHorizontal } from "lucide-react";
import * as React from "react";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "../../components/ui/alert-dialog";
import { Button } from "../../components/ui/button";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../../components/ui/dropdown-menu";
import { Input } from "../../components/ui/input";
import { Label } from "../../components/ui/label";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../components/ui/table";
import { toast } from "../../components/ui/use-toast";
import moment from "moment";
import { GET_ALL_TICKETS } from "../../graphql/Queries";
import { cn } from "../../lib/utils";
import { client } from "../../graphql/apollo-client";
import { CHANGE_TICKET_STATUS } from "../../graphql/Mutations";
import { getUserRole } from "..//../lib/getUserRole";

export type Entity = {
  firstName: string;
  lastName: string;
  serialNumber: number;
  date: string;
  value: number;
  lateInterest: number;
  paid: number;
  debt: number;
  isPaid: true | false;
};

function DropdownMenuCell({ row, entity }: any) {
  const [amountPaid, setAmountPaid] = React.useState(0);
  const [isPartialAmount, setIsPartialAmount] = React.useState(false);
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <div className="flex w-full px-1">
          {entity?.isPaid === false &&
          getUserRole() !== undefined &&
          getUserRole() !== "FINANCE_READ" ? (
            <Button
              variant="ghost"
              className="h-8 w-full p-0 hover:bg-gray-200"
            >
              <span className="sr-only">Hap menunë</span>
              <MoreHorizontal className="h-4 w-4" />
            </Button>
          ) : (
            ""
          )}
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        {entity?.isPaid === false &&
        getUserRole() !== undefined &&
        getUserRole() !== "FINANCE_READ" ? (
          <AlertDialog>
            <AlertDialogTrigger asChild>
              <Button variant="ghost">Paguaj gjobën</Button>
            </AlertDialogTrigger>
            <AlertDialogContent>
              <AlertDialogHeader>
                <AlertDialogTitle>Jeni i sigurt?</AlertDialogTitle>
                <AlertDialogDescription className="flex flex-col gap-1">
                  <p>
                    Jeni i sigurt që dëshironi të ndryshoni statusin e pagesave
                    për gjobës{" "}
                    <span className="font-semibold">
                      {/* "{entity?.companyName} (Nipt: {entity.nipt})" */}
                    </span>{" "}
                    në të paguara?
                  </p>
                  <p className="text-amber-600 italic">
                    Ky veprim është i pakthyeshëm dhe mund të ndikojë në të
                    dhënat tatimore të gjobës. Ju lutemi sigurohuni përpara se
                    të vazhdoni.
                  </p>
                  <div className="w-full flex flex-row items-center justify-around mt-4">
                    <Button
                      variant={!isPartialAmount ? "secondary" : "outline"}
                      className={`${
                        !isPartialAmount
                          ? "bg-green-200 text-green-700 hover:bg-green-200"
                          : ""
                      } font-semibold`}
                      onClick={() => {
                        setIsPartialAmount(false);
                        setAmountPaid(0);
                      }}
                    >
                      Paguaj plotësisht
                    </Button>
                    <Button
                      variant={isPartialAmount ? "secondary" : "outline"}
                      className={`${
                        isPartialAmount
                          ? "bg-green-200 text-green-700 hover:bg-green-200"
                          : ""
                      } font-semibold`}
                      onClick={() => setIsPartialAmount(true)}
                    >
                      Paguaj pjesërisht
                    </Button>
                  </div>
                  {isPartialAmount ? (
                    <div className="w-full mt-4 px-11">
                      <Input
                        type="number"
                        pattern="[0-9]*"
                        min={0}
                        placeholder="Vlera e faturës ..."
                        onChange={(e) => {
                          e.target.value === ""
                            ? setAmountPaid(0)
                            : setAmountPaid(parseInt(e.target.value));
                        }}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </AlertDialogDescription>
              </AlertDialogHeader>
              <AlertDialogFooter>
                <AlertDialogCancel>Anullo</AlertDialogCancel>
                <AlertDialogAction
                  className={`bg-amber-300 text-amber-950 hover:bg-amber-300 ${
                    isPartialAmount && amountPaid === 0
                      ? "cursor-not-allowed"
                      : "cursor-pointer"
                  }`}
                  disabled={isPartialAmount && amountPaid === 0 ? true : false}
                  onClick={() => {
                    client
                      .mutate({
                        mutation: CHANGE_TICKET_STATUS,
                        variables: {
                          ticketId: entity?.id,
                          value: isPartialAmount ? amountPaid : entity?.debt,
                        },
                        refetchQueries: [GET_ALL_TICKETS],
                      })
                      .then((data) => {
                        toast({
                          title: "Statusi u përditësua",
                          description:
                            "Statusi i pagesës u përditësua me sukses!",
                        });
                      });
                  }}
                >
                  {!isPartialAmount ? "Shëno si të paguar" : "Konfirmo shumën"}
                </AlertDialogAction>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
        ) : (
          ""
        )}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

export const columns: ColumnDef<Entity>[] = [
  {
    accessorKey: "firstName",
    header: "Emri",
    cell: ({ row }) => (
      <div className="px-3.5 py-2 capitalize rounded-lg my-2 text-center">
        {row.getValue("firstName")}
      </div>
    ),
  },
  {
    accessorKey: "lastName",
    header: "Mbiemri",
    cell: ({ row }) => (
      <div className="px-3.5 py-2 capitalize rounded-lg my-2 text-center">
        {row.getValue("lastName")}
      </div>
    ),
  },
  {
    accessorKey: "serialNumber",
    header: "Nr.Serial",
    cell: ({ row }) => (
      <div className="px-3.5 py-2 capitalize rounded-lg my-2 text-center">
        {row.getValue("serialNumber")}
      </div>
    ),
  },
  {
    accessorKey: "date",
    header: "Data",
    cell: ({ row }) => (
      <div className="px-3.5 py-2 capitalize rounded-lg my-2 text-center">
        {moment(row.getValue("date")).format("DD.MM.YYYY")}.
      </div>
    ),
  },
  {
    accessorKey: "value",
    header: "Vlera",
    cell: ({ row }) => (
      <div className="px-3.5 py-2 capitalize rounded-lg my-2 text-center">
        {row.getValue("value")}
      </div>
    ),
  },
  {
    accessorKey: "lateInterest",
    header: "Kamatvonesa",
    cell: ({ row }) => (
      <div className="px-3.5 py-2 capitalize rounded-lg my-2 text-center">
        {row.getValue("lateInterest")}
      </div>
    ),
  },
  {
    accessorKey: "paid",
    header: "Vlera e paguar",
    cell: ({ row }) => (
      <div className="px-3.5 py-2 capitalize rounded-lg my-2 text-center">
        {row.getValue("paid")}
      </div>
    ),
  },
  {
    accessorKey: "debt",
    header: "Vlera e papaguar",
    cell: ({ row }) => (
      <div className="px-3.5 py-2 capitalize rounded-lg my-2 text-center">
        {row.getValue("debt")}
      </div>
    ),
  },
  {
    accessorKey: "isPaid",
    header: "Statusi",
    cell: ({ row }) => (
      <div
        className={cn(
          "px-3.5 py-2 rounded-lg my-2 text-center",
          row.getValue("isPaid") === true
            ? "bg-green-200 text-green-700 font-semibold"
            : "bg-red-200 text-red-950 font-semibold"
        )}
      >
        {row.getValue("isPaid") === true ? "Paguar" : "Për tu paguar"}
      </div>
    ),
  },
  {
    id: "actions",
    enableHiding: false,
    cell: ({ row }) => <DropdownMenuCell entity={row.original} row={row} />,
  },
];

export function TicketsEntitiesTable(props: any) {
  const { ticketsData, ticketsDataLoading, ticketsDataError } = props;
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );
  const [columnVisibility, setColumnVisibility] =
    React.useState<VisibilityState>({});
  const [rowSelection, setRowSelection] = React.useState({});
  // const [category, setCategory] = React.useState("FIXED")

  const [userFormData, setUserFormData] = React.useState({
    name: "",
    value: 0,
  });

  const handleAddData = (e: any) => {
    setUserFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  // Mutations
  // const [createTax] = useMutation(CREATE_TAX);

  // const handleCreateTax = () => {
  //   createTax({
  //     variables: {
  //       category: "FIXED",
  //       name: userFormData?.name,
  //       value: Number(userFormData?.value),
  //     },
  //     refetchQueries: [GET_ALL_TICKETS],
  //     onCompleted: () => {
  //       toast({
  //         title: "Taksa u shtua me sukses",
  //         description: "Të dhënat e futura për taksën u shtuan me sukses",
  //         duration: 3000,
  //       });
  //     },
  //     onError: (err) => {
  //       toast({
  //         variant: "destructive",
  //         title: "Taksa Nuk mund të shtohet",
  //         description: `Problem në shtimin e taksës`,
  //         duration: 3000,
  //       });
  //     },
  //   });
  // };

  const ticketsTable = useReactTable({
    data: ticketsData?.allTickets,
    columns,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      rowSelection,
    },
  });

  if (ticketsDataLoading) {
    return <div>Loading...</div>;
  }

  if (ticketsDataError) {
    return <div>Error loading data</div>;
  }
  return (
    <div className="w-full">
      <div className="flex items-center py-4">
        <Input
          placeholder="Kërko..."
          value={
            (ticketsTable.getColumn("firstName")?.getFilterValue() as string) ??
            ""
          }
          onChange={(event) =>
            ticketsTable
              .getColumn("firstName")
              ?.setFilterValue(event.target.value)
          }
          className="max-w-sm"
        />
        <div className="ml-auto">
          <AlertDialog>
            {/* <AlertDialogTrigger asChild>
              <Button className="m-auto mx-3" variant="outline">
                Shto taksë
              </Button>
            </AlertDialogTrigger> */}
            <AlertDialogContent>
              <AlertDialogHeader>
                <AlertDialogTitle className="text-center">
                  Regjistro taksë!
                </AlertDialogTitle>
                <AlertDialogDescription>
                  <form action="" className="w-full flex flex-col gap-y-4">
                    <div className="flex flex-col gap-2">
                      <Label htmlFor="name">Emri i taksës:</Label>
                      <Input
                        type="text"
                        id="name"
                        name="name"
                        onChange={handleAddData}
                        required
                      />
                    </div>
                    <div className="flex flex-col gap-2">
                      <Label htmlFor="value">Vlera e taksës:</Label>
                      <Input
                        type="number"
                        min={0}
                        id="value"
                        name="value"
                        onChange={handleAddData}
                        required
                      />
                    </div>
                    {/* <div className="flex flex-col gap-2">
                      <Label htmlFor="category" className="mb-3">
                        Kategoria e taksës:
                      </Label>
                      <RadioGroup className="flex flex-row items-center border border-gray-200 rounded-lg px-2" name="category">
                        <div className="flex items-center w-full gap-1">
                          <RadioGroupItem
                            value="FIXED"
                            id="r1"
                            checked={category === "FIXED"}
                            onClick={(e: any) => setCategory(e.target.value)}
                          />
                          <Label
                            htmlFor="r1"
                            className="py-3 w-full text-amber-950"
                          >
                            Fikse
                          </Label>
                        </div>
                        <div className="flex items-center w-full gap-1">
                          <RadioGroupItem
                            value="CHANGE"
                            id="r2"
                            checked={category === "CHANGE"}
                            onClick={(e: any) => setCategory(e.target.value)}
                          />
                          <Label
                            htmlFor="r2"
                            className="py-3 w-full text-amber-950"
                          >
                            E ndryshueshme
                          </Label>
                        </div>
                      </RadioGroup>
                    </div> */}
                  </form>
                </AlertDialogDescription>
              </AlertDialogHeader>
              <AlertDialogFooter>
                <AlertDialogCancel>Anullo</AlertDialogCancel>
                <AlertDialogAction
                  className="bg-amber-200 text-amber-950 hover:bg-amber-100"
                  // onClick={handleCreateTax}
                >
                  Shto Taksën
                </AlertDialogAction>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="outline" className="ml-auto">
                Filtro të dhënat <ChevronDown className="ml-2 h-4 w-4" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              {ticketsTable
                ?.getAllColumns()
                .filter((column) => column.getCanHide())
                .map((column) => {
                  return (
                    <DropdownMenuCheckboxItem
                      key={column.id}
                      className="capitalize"
                      checked={column.getIsVisible()}
                      onCheckedChange={(value) =>
                        column.toggleVisibility(!!value)
                      }
                    >
                      {String(column.columnDef?.header)}
                    </DropdownMenuCheckboxItem>
                  );
                })}
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </div>
      <div className="rounded-md border w-full max-w-[calc(100vw-32px)]">
        <Table>
          <TableHeader>
            {ticketsTable?.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead key={header.id} className="text-center">
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {ticketsData?.allTickets ? (
              ticketsTable.getRowModel()?.rows.map((row: any) => {
                return (
                  <TableRow
                    key={row.id}
                    data-state={row.getIsSelected() && "selected"}
                  >
                    {row.getVisibleCells().map((cell: any) => (
                      <TableCell key={cell.id}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns?.length}
                  className="h-24 text-center"
                >
                  Asnjë rezultat.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <div className="flex items-center justify-end space-x-2 py-4">
        <div className="flex-1 text-sm text-muted-foreground">
          {ticketsTable.getFilteredSelectedRowModel()?.rows?.length} nga{" "}
          {ticketsTable.getFilteredRowModel()?.rows?.length} rrjeshta zgjedhur.
        </div>
        <div className="space-x-2">
          <Button
            variant="outline"
            size="sm"
            onClick={() => ticketsTable.previousPage()}
            disabled={!ticketsTable.getCanPreviousPage()}
          >
            Mbrapa
          </Button>
          <Button
            variant="outline"
            size="sm"
            onClick={() => ticketsTable.nextPage()}
            disabled={!ticketsTable.getCanNextPage()}
          >
            Përpara
          </Button>
        </div>
      </div>
    </div>
  );
}
