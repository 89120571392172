import { useQuery } from "@apollo/client";
import { GET_ALL_TICKETS } from "../../graphql/Queries";
import { TicketsEntitiesTable } from "./ticketsData-table";

const TicketsPageComponent = () => {
  const {
    data: ticketsData,
    loading: ticketsDataLoading,
    error: ticketsDataError,
  } = useQuery(GET_ALL_TICKETS);
  return (
    <div>
      <TicketsEntitiesTable
        ticketsData={ticketsData}
        ticketsDataLoading={ticketsDataLoading}
        ticketsDataError={ticketsDataError}
      />
    </div>
  );
};

export default TicketsPageComponent;
