import Layout from "../../components/Layout";
import DebtsPageComponent from "./page";

const DebtsPage = () => {
  return (
    <Layout>
      <DebtsPageComponent />
    </Layout>
  );
};

export default DebtsPage;
