import { useCallback, useState } from "react";
import { Button } from "../../components/ui/button";
import { Input } from "../../components/ui/input";
import useUser from "../../hooks/useUser";
import { LockIcon } from "lucide-react";

type LoginData = {
  email?: string;
  password?: string;
} | null;

const AuthPageComponent = () => {
  const { loginLoading, logInUser } = useUser();

  const [loginData, setLoginData] = useState<LoginData>(null);

  const handleInputFieldChange = useCallback(function (
    e: React.ChangeEvent<HTMLInputElement>
  ) {
    setLoginData((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.value,
    }));
  },
  []);

  return (
    <div className="w-full h-screen flex flex-col justify-center items-center">
      <div className="absolute top-2 right-2 flex flex-row gap-2 items-center justify-center">
        <svg
          width="31"
          height="40"
          viewBox="0 0 31 40"
          fill="none"
          xmlns="
http://www.w3.org/2000/svg"
        >
          <path
            d="M17.2122 24.6826C14.9015 24.6826 12.7512 23.6603 11.3115 21.8769C11.0198 21.5161 11.0798 20.9906 11.4449 20.7023C11.81 20.4147 12.3424 20.474 12.6335 20.8341C13.7512 22.2171 15.4201 23.0107 17.2122 23.0107C20.4433 23.0107 23.0716 20.4134 23.0716 17.2207C23.0716 14.1717 20.6258 11.6326 17.5027 11.4403C17.0376 11.4115 16.6827 11.0148 16.7113 10.554C16.7404 10.0937 17.134 9.74143 17.6088 9.77137C21.621 10.0189 24.7636 13.2908 24.7636 17.2207C24.7636 21.3357 21.376 24.6826 17.2122 24.6826Z"
            fill="#62B9B1"
          ></path>
          <path
            d="M6.40108 39.8957C6.19062 39.8957 5.97957 39.819 5.81644 39.6644C2.31606 36.3588 0.388794 31.9047 0.388794 27.1233C0.388794 23.3168 1.612 19.704 3.92678 16.673C4.20818 16.3038 4.7382 16.2313 5.11176 16.5093C5.48413 16.7868 5.55872 17.3111 5.27732 17.6797C3.18632 20.4183 2.08078 23.6841 2.08078 27.1233C2.08078 31.4444 3.82307 35.469 6.98444 38.4557C7.32284 38.7745 7.33561 39.3035 7.01178 39.638C6.84622 39.8094 6.62363 39.8957 6.40108 39.8957Z"
            fill="#62B9B1"
          ></path>
          <path
            d="M7.8109 14.8188C7.55679 14.8188 7.30571 14.7063 7.13835 14.4911C6.85512 14.1249 6.92548 13.6 7.29603 13.3195C7.40033 13.2404 7.50585 13.1631 7.61377 13.0858C8.68418 12.3188 9.8631 11.6776 11.1178 11.1784C11.5502 11.0058 12.0432 11.2132 12.2179 11.6416C12.3926 12.0701 12.1821 12.5573 11.7485 12.7293C10.6223 13.1775 9.56533 13.7522 8.60836 14.4377C8.51375 14.5061 8.41792 14.5761 8.32454 14.6468C8.17113 14.7625 7.99102 14.8188 7.8109 14.8188Z"
            fill="#62B9B1"
          ></path>
          <path
            d="M12.0329 30.3557C12.0153 30.3557 11.9977 30.355 11.9795 30.3544C7.96728 30.1064 4.82471 26.8344 4.82471 22.9052C4.82471 18.7907 8.2123 15.4433 12.3767 15.4433C14.6868 15.4433 16.8371 16.4662 18.2774 18.249C18.5686 18.6097 18.5085 19.1359 18.1434 19.4236C17.7783 19.7124 17.2465 19.653 16.9542 19.2917C15.8371 17.9086 14.1688 17.1152 12.3767 17.1152C9.14561 17.1152 6.51669 19.713 6.51669 22.9052C6.51669 25.9542 8.96245 28.4932 12.0857 28.6856C12.5508 28.7149 12.9056 29.111 12.8765 29.5724C12.8485 30.0153 12.4762 30.3557 12.0329 30.3557Z"
            fill="#62B9B1"
          ></path>
          <path
            d="M24.9862 23.786C24.8084 23.786 24.6296 23.7309 24.4768 23.617C24.1044 23.339 24.0298 22.8147 24.3112 22.4462C26.4017 19.7082 27.5072 16.4429 27.5072 13.0026C27.5072 8.68135 25.7654 4.65677 22.6035 1.67011C22.2656 1.35131 22.2529 0.822173 22.5761 0.488991C22.8988 0.154609 23.4355 0.142627 23.7721 0.461428C27.2725 3.7663 29.1991 8.21993 29.1991 13.0026C29.1991 16.809 27.976 20.4225 25.6618 23.4535C25.4956 23.671 25.2421 23.786 24.9862 23.786Z"
            fill="#62B9B1"
          ></path>
          <path
            d="M18.1549 29.008C17.8202 29.008 17.5024 28.8096 17.3696 28.4836C17.1955 28.0557 17.406 27.5686 17.8396 27.3966C18.9651 26.9489 20.0222 26.3742 20.9797 25.6874C21.0756 25.6192 21.1713 25.549 21.2647 25.4783C21.6365 25.1984 22.1672 25.2686 22.4497 25.636C22.7335 26.0033 22.662 26.5283 22.2903 26.8082C22.1872 26.8854 22.081 26.9633 21.9737 27.0406C20.9039 27.8083 19.725 28.4489 18.4703 28.9474C18.3672 28.9882 18.2599 29.008 18.1549 29.008Z"
            fill="#62B9B1"
          ></path>
        </svg>{" "}
        <a
          href="https://www.laconics.io"
          target="_blank"
          rel="noreferrer"
          className="text font-semibold"
        >
          Mundësuar nga{" "}
          <span className="font-bold text-[#62B9B1]">LACONICS</span> ↗
        </a>
      </div>
      <form
        onSubmit={(e) => e.preventDefault()}
        className="max-w-sm w-full flex flex-col gap-3 justify-center items-center"
      >
        <div className="rounded flex justify-center items-center">
          <img src="./bashkiamat.png" alt="logo" width={200} height={200} />
        </div>
        <div className="rounded flex flex-col text-center justify-center items-center">
          <h1 className="text-xl text-amber-950 font-bold whitespace-nowrap">
            BASHKIA
            <span className="bg-amber-300 px-[5px] rounded-md">MAT</span>
            <span> - P.P.M.A.V</span>
          </h1>
          <p className="text-xs font-bold">
            Platforma e Planifikimit dhe Menaxhimit së të Ardhurave Vendore.
          </p>
        </div>
        <Input
          name="email"
          type="text"
          placeholder="Vendos email-in ose emrin e përdoruesit"
          onChange={handleInputFieldChange}
        />
        <Input
          name="password"
          type="password"
          placeholder="Vendos fjalëkalimin"
          onChange={handleInputFieldChange}
        />
        <Button
          type="submit"
          className="w-full bg-amber-300 hover:bg-amber-200 text-amber-950 font-bold gap-1"
          onClick={() => logInUser && logInUser(loginData)}
        >
          {loginLoading ? (
            <div
              className="inline-block h-4 w-4 animate-spin rounded-full border border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
              role="status"
            >
              <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                Loading...
              </span>
            </div>
          ) : (
            <>
              <LockIcon className="w-4 h-4" />
              Kyçu
            </>
          )}
        </Button>
      </form>
    </div>
  );
};

export default AuthPageComponent;
