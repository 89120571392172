import { useQuery } from "@apollo/client";
import { GET_ALL_DEBTS } from "../../graphql/Queries";
import { DebtsEntitiesTable } from "./ticketsData-table";

const DebtsPageComponent = () => {
  const {
    data: debtsData,
    loading: debtsDataLoading,
    error: debtsDataError,
  } = useQuery(GET_ALL_DEBTS);
  return (
    <div>
      <DebtsEntitiesTable
        debtsData={debtsData}
        debtsDataLoading={debtsDataLoading}
        debtsDataError={debtsDataError}
      />
    </div>
  );
};

export default DebtsPageComponent;
