import {
  BarChart,
  Building,
  Coins,
  Eye,
  EyeOff,
  KeyRound,
  LayoutDashboard,
  LockKeyhole,
  LogOut,
  Receipt,
  Ticket,
  Users,
} from "lucide-react";
import NavbarLink from "./NavbarLink";
import { ScrollArea } from "./ui/scroll-area";
import { Link } from "react-router-dom";
import useUser from "../hooks/useUser";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "./ui/alert-dialog";
import { Button } from "./ui/button";
import { Label } from "./ui/label";
import { Input } from "./ui/input";
import { useState } from "react";
import { useMutation } from "@apollo/client";
import { CHANGE_PASSWORD } from "../graphql/Mutations";
import { toast } from "./ui/use-toast";
import { getUserRole } from "../lib/getUserRole";

const NavbarContent = () => {
  const { logOutUser } = useUser();
  const [isOldPassword, setIsOldPassword] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [isNewPassword, setIsNewPassword] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [isConfirmPassword, setIsConfirmPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");

  const [changePassword, { error }] = useMutation(CHANGE_PASSWORD);
  const handleChangePassword = () => {
    setIsOldPassword(false);
    setIsNewPassword(false);
    setIsConfirmPassword(false);
    setOldPassword("");
    setNewPassword("");
    setConfirmPassword("");
    changePassword({
      errorPolicy: "all",
      variables: {
        oldPassword: oldPassword,
        newPassword: newPassword,
      },
      onCompleted: () => {
        toast({
          variant: "default",
          description: "Fjalëkalimi u ndryshua me sukses",
          duration: 3000,
        });
      },
      onError: (err) => {
        toast({
          variant: "destructive",
          title: `Problem në ndryshimin e fjalëkalimit`,
          description: `Dicka shkoi keq`,
          duration: 3000,
        });
      },
    });
  };
  const handleCancelPassword = () => {
    setIsOldPassword(false);
    setIsNewPassword(false);
    setIsConfirmPassword(false);
    setOldPassword("");
    setNewPassword("");
    setConfirmPassword("");
  };
  return (
    <>
      <div className="h-14 flex-none flex justify-center items-center">
        <Link to="/" className="select-none">
          <div className="rounded flex flex-col justify-center items-center">
            <h1 className="text-lg text-amber-950 font-bold whitespace-nowrap">
              BASHKIA
              <span className="bg-amber-300 px-[5px] rounded-md">MAT</span>
            </h1>
            <p className="text-xs font-semibold text-amber-950">P.P.M.A.V</p>
          </div>
        </Link>
      </div>
      <ScrollArea className="w-full h-[calc(100%-104px)] lg:h-full overflow-x-auto">
        <div className="flex flex-col justify-start items-stretch gap-4">
          <NavbarLink
            to="/"
            icon={<LayoutDashboard className="w-4 h-4" />}
            label="Paneli"
          />
          <NavbarLink
            to="/raports"
            icon={<BarChart className="w-4 h-4" />}
            label="Raportet"
          />
          <NavbarLink
            to="/subjektet"
            icon={<Building className="w-4 h-4" />}
            label="Subjektet"
          />
          <NavbarLink
            to="/taksat"
            icon={<Receipt className="w-4 h-4" />}
            label="Taksat"
          />
          {getUserRole() === "SUPERADMIN" && (
            <NavbarLink
              to="/perdoruesit"
              icon={<Users className="w-4 h-4" />}
              label="Përdoruesit"
            />
          )}
          <NavbarLink
            to="/gjobat"
            icon={<Ticket className="w-4 h-4" />}
            label="Gjobat"
          />
          <NavbarLink
            to="/debitoret"
            icon={<Coins className="w-4 h-4" />}
            label="Debitorët"
          />
          <AlertDialog>
            <AlertDialogTrigger asChild>
              <Button
                variant="ghost"
                className="flex items-center justify-start hover:bg-amber-100"
              >
                <p className="flex flex-row items-center justify-start gap-1">
                  <KeyRound className="w-4 h-4" /> Ndrysho Fjalëkalimin
                </p>
              </Button>
            </AlertDialogTrigger>
            <AlertDialogContent className="min-w-[40svw] flex flex-col">
              <AlertDialogHeader>
                <AlertDialogTitle className="text-center">
                  Ndrysho Fjalëkalimin !
                </AlertDialogTitle>
              </AlertDialogHeader>
              <div className="w-full flex items-center justify-center">
                <div className=" w-full flex flex-col p-4 gap-4">
                  <div className=" relative flex flex-col gap-2">
                    <Label>Fjalëkalimi i vjetër</Label>
                    <Input
                      type={!isOldPassword ? "password" : "text"}
                      id="coveredParkingSurface"
                      name="coveredParkingSurface"
                      placeholder="Fjalëkalimi i vjetër ..."
                      onChange={(e) => setOldPassword(e.target.value)}
                      required
                    />
                    {!isOldPassword ? (
                      <Eye
                        onClick={() => setIsOldPassword(true)}
                        className="absolute top-2/3 transform -translate-y-1/2 right-2 w-5 h-5 cursor-pointer"
                      />
                    ) : (
                      <EyeOff
                        onClick={() => setIsOldPassword(false)}
                        className="absolute top-2/3 transform -translate-y-1/2 right-2 w-5 h-5 cursor-pointer"
                      />
                    )}
                  </div>
                  <div className=" relative flex flex-col gap-2">
                    <Label>Fjalëkalimi i ri</Label>
                    <Input
                      type={!isNewPassword ? "password" : "text"}
                      id="coveredParkingSurface"
                      name="coveredParkingSurface"
                      placeholder="Fjalëkalimi i ri ..."
                      onChange={(e) => setNewPassword(e.target.value)}
                      required
                    />
                    {!isNewPassword ? (
                      <Eye
                        onClick={() => setIsNewPassword(true)}
                        className="absolute top-2/3 transform -translate-y-1/2 right-2 w-5 h-5 cursor-pointer"
                      />
                    ) : (
                      <EyeOff
                        onClick={() => setIsNewPassword(false)}
                        className="absolute top-2/3 transform -translate-y-1/2 right-2 w-5 h-5 cursor-pointer"
                      />
                    )}
                  </div>
                  <div className=" relative flex flex-col gap-2">
                    <Label>Konfirmo Fjalëkalimin e ri</Label>
                    <Input
                      type={!isConfirmPassword ? "password" : "text"}
                      id="coveredParkingSurface"
                      name="coveredParkingSurface"
                      placeholder="Konfirmimi i fjalëkalimit të ri ..."
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      required
                    />
                    {!isConfirmPassword ? (
                      <Eye
                        onClick={() => setIsConfirmPassword(true)}
                        className="absolute top-2/3 transform -translate-y-1/2 right-2 w-5 h-5 cursor-pointer"
                      />
                    ) : (
                      <EyeOff
                        onClick={() => setIsConfirmPassword(false)}
                        className="absolute top-2/3 transform -translate-y-1/2 right-2 w-5 h-5 cursor-pointer"
                      />
                    )}
                  </div>
                  {newPassword !== "" &&
                  confirmPassword !== "" &&
                  newPassword !== confirmPassword ? (
                    <p className="text-red-400">
                      Fjalëkalimet nuk përputhen me njëri tjetrin.
                    </p>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <AlertDialogFooter>
                <AlertDialogCancel onClick={handleCancelPassword}>
                  Anullo
                </AlertDialogCancel>
                <AlertDialogAction
                  disabled={
                    newPassword !== "" &&
                    confirmPassword !== "" &&
                    newPassword === confirmPassword
                      ? false
                      : true
                  }
                  className={`bg-amber-200 text-amber-950 hover:bg-amber-100 ${
                    newPassword !== "" &&
                    confirmPassword !== "" &&
                    newPassword === confirmPassword
                      ? "cursor-pointer"
                      : "cursor-not-allowed"
                  }`}
                  onClick={handleChangePassword}
                >
                  Ndrysho Fjalëkalimin
                </AlertDialogAction>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
        </div>
      </ScrollArea>
      <div onClick={logOutUser}>
        <NavbarLink
          to="/"
          className="mb-4 bg-red-500 hover:bg-red-500 text-white flex justify-center"
          icon={<LogOut className="w-4 h-4" />}
          label="Dil"
        />
      </div>
    </>
  );
};

export default NavbarContent;
