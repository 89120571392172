import React, { useState } from "react";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "./ui/dropdown-menu";
import { Button } from "./ui/button";
import { ChevronDown } from "lucide-react";
import { Separator } from "./ui/separator";
import { CheckedState } from "@radix-ui/react-checkbox";

const FilterData = ({ table }: any) => {
  const [filterOptions, setFilterOptions] = useState("");

  const handleFilterOption = (option: string) => {
    setFilterOptions(option);
  };
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="outline" className="ml-auto">
          {(table
            .getColumn("paymentStatus")
            ?.getFilterValue() as CheckedState) ?? "Filtro Të dhënat"}{" "}
          <ChevronDown className="ml-2 h-4 w-4" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="center">
        <Button
          variant="outline"
          className="border-0 hover:bg-transparent"
          onClick={() =>
            handleFilterOption(filterOptions === "pagesa" ? "" : "pagesa")
          }
        >
          <p className="w-full font-semibold text-xs text-center text-[#62B9B1]">
            Statusi i pagesave
          </p>
        </Button>
        <Separator />
        {filterOptions && filterOptions === "pagesa" ? (
          <>
            <DropdownMenuCheckboxItem
              className="capitalize  text-green-900 bg-green-200 hover:bg-green-300 cursor-pointer w-full my-1 font-semibold"
              onCheckedChange={() => {
                table.getColumn("paymentStatus")?.setFilterValue("PAGUAR");
                setFilterOptions("");
              }}
            >
              Paguar
            </DropdownMenuCheckboxItem>
            <DropdownMenuCheckboxItem
              className="capitalize  text-red-900 bg-red-200 hover:bg-red-300 cursor-pointer w-full my-1 font-semibold"
              onCheckedChange={() => {
                table.getColumn("paymentStatus")?.setFilterValue("PAPAGUAR");
                setFilterOptions("");
              }}
            >
              Pa Paguar
            </DropdownMenuCheckboxItem>
            <DropdownMenuCheckboxItem
              className="capitalize  text-gray-900 bg-gray-200 hover:bg-gray-300 cursor-pointer w-full my-1 font-semibold"
              onCheckedChange={() => {
                table.getColumn("paymentStatus")?.setFilterValue("PAFATUR");
                setFilterOptions("");
              }}
            >
              Pa Faturë
            </DropdownMenuCheckboxItem>
          </>
        ) : (
          ""
        )}
        <Button
          variant="outline"
          className="border-0 hover:bg-transparent"
          onClick={() =>
            handleFilterOption(filterOptions === "kompania" ? "" : "kompania")
          }
        >
          <p className="w-full font-semibold text-xs text-center text-[#62B9B1]">
            Statusi i kompanisë
          </p>
        </Button>
        {filterOptions && filterOptions === "kompania" ? (
          <>
            <DropdownMenuCheckboxItem
              className="capitalize  text-green-900 bg-green-200 hover:bg-green-300 cursor-pointer w-full my-1 font-semibold"
              onCheckedChange={() => {
                table.getColumn("companyStatus")?.setFilterValue("AKTIV");
                setFilterOptions("");
              }}
            >
              Aktiv
            </DropdownMenuCheckboxItem>
            <DropdownMenuCheckboxItem
              className="capitalize  text-red-900 bg-red-200 hover:bg-red-300 cursor-pointer w-full my-1 font-semibold"
              onCheckedChange={() => {
                table.getColumn("companyStatus")?.setFilterValue("PASIV");
                setFilterOptions("");
              }}
            >
              Pasiv
            </DropdownMenuCheckboxItem>
            <DropdownMenuCheckboxItem
              className="capitalize  text-yellow-900 bg-yellow-200 hover:bg-yellow-300 cursor-pointer w-full my-1 font-semibold"
              onCheckedChange={() => {
                table.getColumn("companyStatus")?.setFilterValue("PEZULLUAR");
                setFilterOptions("");
              }}
            >
              Pezulluar
            </DropdownMenuCheckboxItem>
            <DropdownMenuCheckboxItem
              className="capitalize  text-gray-900 bg-gray-200 hover:bg-gray-300 cursor-pointer w-full my-1 font-semibold"
              onCheckedChange={() => {
                table.getColumn("companyStatus")?.setFilterValue("MBYLLUR");
                setFilterOptions("");
              }}
            >
              Mbyllur
            </DropdownMenuCheckboxItem>
            <DropdownMenuCheckboxItem
              className="capitalize  text-blue-900 bg-blue-200 hover:bg-blue-300 cursor-pointer w-full my-1 font-semibold"
              onCheckedChange={() => {
                table
                  .getColumn("companyStatus")
                  ?.setFilterValue("CRREGJISTRUAR");
                setFilterOptions("");
              }}
            >
              Crregjistruar
            </DropdownMenuCheckboxItem>
          </>
        ) : (
          ""
        )}
        <Separator />
        <DropdownMenuCheckboxItem
          className="capitalize"
          onCheckedChange={() => {
            table.getColumn("companyStatus")?.setFilterValue("");
            table.getColumn("paymentStatus")?.setFilterValue("");
            setFilterOptions("");
          }}
        >
          <p className="font-semibold text-xs text-center text-[#62B9B1] cursor-pointer">
            Hiq Filtrimin
          </p>{" "}
        </DropdownMenuCheckboxItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default FilterData;
