import { jwtDecode } from "jwt-decode";

export function getUserRole() {
  const token = localStorage?.getItem("token");
  const data: any = token && jwtDecode(token);

  if (data) {
    return data?.UserRole;
  } else {
    return undefined;
  }
}
