import { ApolloClient, gql, useMutation, useQuery } from "@apollo/client";
import { useSearchParams } from "react-router-dom";
import {
  ENTITY_BY_ID_QUERY,
  ENTITY_TAXES_BY_ID,
  GET_BILLS_QUERY,
  GET_SUBJECTS_QUERY,
  GET_TAXES_QUERY,
} from "../../graphql/Queries";
import { EntityBillTable } from "./entity-bill-table";
import { EntityTable } from "./entity-data-table";
import EntityTaxes from "./entityTaxes";
import { GENERATE_BILL } from "../../graphql/Mutations";
import { toast } from "../../components/ui/use-toast";
import { EntityStatusHistory } from "./EntityStatusHistory";

const EntityPageComponent = () => {
  let [searchParams] = useSearchParams();
  const id = searchParams.get("id");

  const {
    data: entityData,
    loading,
    error,
  } = useQuery(ENTITY_BY_ID_QUERY, {
    fetchPolicy: "cache-and-network",
    variables: {
      in: {
        companyId: id,
        userId: null,
      },
    },
  });
  const {
    data: taxesData,
    loading: taxesDataLoading,
    error: taxesDataError,
  } = useQuery(GET_TAXES_QUERY);
  const {
    data: entityTaxesData,
    loading: entityTaxesDataLoading,
    error: entityTaxesDataError,
  } = useQuery(ENTITY_TAXES_BY_ID, {
    variables: {
      CompanyId: id,
    },
  });

  const {
    data: billsData,
    loading: billsDataLoading,
    error: billsDataError,
  } = useQuery(GET_BILLS_QUERY, {
    variables: {
      companyId: id,
    },
  });
  const [generateBill] = useMutation(GENERATE_BILL, {
    variables: {
      companyId: id,
    },
    onCompleted: () => {
      toast({
        title: "Fatura u gjenerua me sukses",
        description: "Shikoni listën e mësipërme për më shumë detaje",
        duration: 3000,
      });
    },
    onError: (err) => {
      toast({
        variant: "destructive",
        title: "Problem në gjenerimin e faturës",
        description: `Problem, Dicka shkoi keq`,
        duration: 3000,
      });
    },
    refetchQueries: [GET_BILLS_QUERY, GET_SUBJECTS_QUERY],
  });

  return (
    <div className="w-full flex flex-col gap-6">
      <EntityTable entityData={entityData} loading={loading} error={error} />
      <EntityTaxes
        taxesData={taxesData}
        entityData={entityData}
        entityTaxesData={entityTaxesData}
        id={id}
      />
      <EntityBillTable
        loading={loading}
        error={error}
        billsData={billsData}
        generateBill={generateBill}
        entityTaxesData={entityTaxesData}
      />
      <EntityStatusHistory
        entityData={entityData}
        loading={loading}
        error={error}
      />
    </div>
  );
};

export default EntityPageComponent;
