import { Document, Image, Page, Text, View } from "@react-pdf/renderer";

interface Props {
  data: any;
}

const RaportMujor = ({ data }: Props) => {
  return (
    <Document>
      <Page
        size="A4"
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 28,
          fontSize: 12,
          padding: 35,
        }}
      >
        <View
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",

            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Image
            style={{ width: 120, height: 120 }}
            src="/bashkiamat-raport-logo.png"
          />
          <Text style={{ fontSize: 17, textAlign: "center" }}>Bashkia Mat</Text>
          <Text style={{ fontSize: 12, textAlign: "center" }}>
            Platforma e Planifikimit dhe Menaxhimit së të Ardhurave Vendore
          </Text>
          <Text style={{ marginTop: 28, fontSize: 12, textAlign: "center" }}>
            Raport mujor
          </Text>
        </View>
        {data?.generateRaport?.map(
          (item: {
            period?: string;
            totalPaid?: number;
            totalDebt?: number;
            totalTransactions?: number;
            averagePaymentTimeDays?: number;
            totalToBePaid?: number;
          }) => (
            <View
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                gap: 7,
                border: 1,
                borderColor: "#000",
                padding: 7,
              }}
            >
              <Text style={{ fontSize: 15, borderBottom: 1 }}>
                {item?.period}
              </Text>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Text>Numri i gjobave:</Text>
                <Text>{item.totalTransactions} gjoba</Text>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Text>Shuma totale e gjobave (në Lekë):</Text>
                <Text>{item.totalToBePaid} Lekë</Text>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Text>Shuma e paguar (në Lekë):</Text>
                <Text>{item.totalPaid} Lekë</Text>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Text>Shuma e papaguar (në Lekë):</Text>
                <Text>{item.totalDebt} Lekë</Text>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Text>Koha mesatare e kryerjes së pagesës (në ditë):</Text>
                <Text>{item.averagePaymentTimeDays} ditë</Text>
              </View>
            </View>
          )
        )}
      </Page>
    </Document>
  );
};

export default RaportMujor;
