import {
  ColumnDef,
  ColumnFiltersState,
  SortingState,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import {
  FileSignature,
  ChevronDown,
  Eye,
  ArrowDownToLine,
  Pencil,
} from "lucide-react";
import * as React from "react";
import { Button } from "../../components/ui/button";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "../../components/ui/dropdown-menu";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../components/ui/table";
import { cn } from "../../lib/utils";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "../../components/ui/alert-dialog";
import {
  ENTITY_BY_ID_QUERY,
  GET_BILLS_QUERY,
  GET_BILL_BY_ID_QUERY,
  GET_SUBJECTS_QUERY,
  GET_TAXES_QUERY,
} from "../../graphql/Queries";
import { client } from "../../graphql/apollo-client";
import { toast } from "../../components/ui/use-toast";
import {
  CHANGE_BILL_PAYMENT_STATUS,
  CHANGE_PAYMENT_STATUS,
} from "../../graphql/Mutations";
import { UUID } from "crypto";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import Bill from "../../components/Bill";
import EditBill from "../../components/EditBill";
import { useQuery } from "@apollo/client";
import { Input } from "../../components/ui/input";
import { getUserRole } from "../../lib/getUserRole";
import { useSearchParams } from "react-router-dom";
import BillAssessment from "../../components/BillAssessment";

export type Entity = {
  numberOfBIll: number;
  value: number;
  billSTatus: "PAYED" | "TOPAY" | "PARTIAL";
  debt: number;
  id: UUID;
  companyName: string;
};

interface DataTypes {
  companyName: string;
  debt: number;
  id: string;
  nipt: string;
  numberOfBIll: string;
  taxesList: { taxName: string; valueString: string; __typename?: any }[];
  valueString: number;
  __typename?: any;
}

function BillCell({ row, entity }: any) {
  const [data, setData] = React.useState<DataTypes | null>(null);
  const [amountPaid, setAmountPaid] = React.useState(0);
  const [isPartialAmount, setIsPartialAmount] = React.useState(false);
  let [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const {
    data: billData,
    loading,
    error,
  } = useQuery(GET_BILL_BY_ID_QUERY, {
    variables: {
      billId: entity.id,
    },
    fetchPolicy: "network-only",
  });

  const { data: entityByIdData } = useQuery(ENTITY_BY_ID_QUERY, {
    fetchPolicy: "cache-and-network",
    variables: {
      in: {
        companyId: id,
        userId: null,
      },
    },
  });

  React.useEffect(() => {
    client
      .query({
        query: GET_BILL_BY_ID_QUERY,
        variables: {
          billId: entity.id,
        },
      })
      .then((res) => {
        if (res?.data?.billById) {
          let updatedData: DataTypes = structuredClone(res?.data?.billById);
          const taxAlreadyExists = updatedData?.taxesList.some(
            (tax) => tax.taxName === "Interesi i vonuar"
          );
          // if (!taxAlreadyExists) {
          //   updatedData.taxesList = [
          //     ...updatedData.taxesList,
          //     {
          //       taxName: "Interesi i vonuar",
          //       valueString: res?.data?.billById?.debt,
          //     },
          //   ];
          // }
          setData(updatedData);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [billData]);

  return (
    <div className="flex items-center justify-end gap-4 py-2 capitalize rounded-lg my-2">
      {row.getValue("billSTatus") !== "PAYED" ? (
        <AlertDialog>
          <AlertDialogTrigger asChild>
            <Button variant="outline">Paguaj faturën</Button>
          </AlertDialogTrigger>
          <AlertDialogContent>
            <AlertDialogHeader>
              <AlertDialogTitle>Jeni i sigurt?</AlertDialogTitle>
              <AlertDialogDescription className="flex flex-col gap-1">
                <p>
                  Jeni i sigurt që dëshironi të ndryshoni statusin e pagesave
                  për subjektin{" "}
                  <span className="font-semibold">
                    {/* "{entity?.companyName} (Nipt: {entity.nipt})" */}
                  </span>{" "}
                  në të paguara?
                </p>
                <p className="text-amber-600 italic">
                  Ky veprim është i pakthyeshëm dhe mund të ndikojë në të dhënat
                  tatimore të subjektit. Ju lutemi sigurohuni përpara se të
                  vazhdoni.
                </p>
                <div className="w-full flex flex-row items-center justify-around mt-4">
                  <Button
                    variant={!isPartialAmount ? "secondary" : "outline"}
                    className={`${
                      !isPartialAmount
                        ? "bg-green-200 text-green-700 hover:bg-green-200"
                        : ""
                    } font-semibold`}
                    onClick={() => {
                      setIsPartialAmount(false);
                      setAmountPaid(0);
                    }}
                  >
                    Paguaj plotësisht
                  </Button>
                  <Button
                    variant={isPartialAmount ? "secondary" : "outline"}
                    className={`${
                      isPartialAmount
                        ? "bg-green-200 text-green-700 hover:bg-green-200"
                        : ""
                    } font-semibold`}
                    onClick={() => setIsPartialAmount(true)}
                  >
                    Paguaj pjesërisht
                  </Button>
                </div>
                {isPartialAmount ? (
                  <div className="w-full mt-4 px-11">
                    <Input
                      type="number"
                      pattern="[0-9]*"
                      min={0}
                      placeholder="Vlera e faturës ..."
                      onChange={(e) => {
                        e.target.value === ""
                          ? setAmountPaid(0)
                          : setAmountPaid(parseInt(e.target.value));
                      }}
                    />
                  </div>
                ) : (
                  ""
                )}
              </AlertDialogDescription>
            </AlertDialogHeader>
            <AlertDialogFooter>
              <AlertDialogCancel>Anullo</AlertDialogCancel>
              <AlertDialogAction
                className={`bg-amber-300 text-amber-950 hover:bg-amber-300 ${
                  isPartialAmount && amountPaid === 0
                    ? "cursor-not-allowed"
                    : "cursor-pointer"
                }`}
                disabled={isPartialAmount && amountPaid === 0 ? true : false}
                onClick={() => {
                  client
                    .mutate({
                      mutation: CHANGE_BILL_PAYMENT_STATUS,
                      variables: {
                        amount: amountPaid,
                        billId: entity?.id,
                        isPartialPayment: isPartialAmount,
                      },
                      refetchQueries: [
                        GET_BILL_BY_ID_QUERY,
                        GET_BILLS_QUERY,
                        GET_SUBJECTS_QUERY,
                        GET_TAXES_QUERY,
                      ],
                    })
                    .then((data) => {
                      toast({
                        title: "Statusi u përditësua",
                        description:
                          "Statusi i pagesës u përditësua me sukses!",
                      });
                    });
                }}
              >
                {!isPartialAmount ? "Shëno si të paguar" : "Konfirmo shumën"}
              </AlertDialogAction>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
      ) : (
        ""
      )}
      <PDFDownloadLink
        document={
          <Bill data={data} setData={setData} entityData={entityByIdData} />
        }
        fileName={`${entity?.companyName}-Vleresimi-Tatimor-Bashkia-Mat`}
      >
        <Button variant="outline">
          <ArrowDownToLine className="h-4 w-4 mr-1" />
        </Button>
      </PDFDownloadLink>
      <AlertDialog>
        <AlertDialogTrigger asChild>
          <Button
            variant="default"
            className="bg-orange-300 hover:bg-orange-200"
          >
            <Eye className="h-4 w-4 text-white" />
          </Button>
        </AlertDialogTrigger>
        <AlertDialogContent className="min-w-[80svw] min-h-[90svh] flex flex-col">
          <AlertDialogHeader className="flex-none">
            <AlertDialogTitle>
              {entity?.companyName} - Vlerësimi tatimor Bashkia Mat
            </AlertDialogTitle>
            <AlertDialogDescription className="flex flex-col gap-1"></AlertDialogDescription>
          </AlertDialogHeader>
          <PDFViewer className="w-full flex-1">
            <Bill
              data={data}
              setData={setData}
              entityData={entityByIdData?.companyById?.businessType}
            />
          </PDFViewer>
          <AlertDialogFooter>
            <AlertDialogCancel>Mbyll</AlertDialogCancel>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
      {row.getValue("billSTatus") !== "PAYED" ? (
        <AlertDialog>
          <AlertDialogTrigger asChild>
            <Button
              variant="default"
              className="bg-orange-300 hover:bg-orange-200"
            >
              <Pencil className="h-4 w-4 text-white" />
            </Button>
          </AlertDialogTrigger>
          <AlertDialogContent className="min-w-[50svw] flex flex-col px-4">
            <AlertDialogHeader className="flex-none">
              <AlertDialogTitle className="font-medium">
                Detyrimet për taksa e tarifa vendore të bisnesit{" "}
                <span className="font-bold">{entity?.companyName}</span> për
                vitin 2024 janë si vijon:
              </AlertDialogTitle>
            </AlertDialogHeader>
            <EditBill data={data} setData={setData} bill={entity.id} />
            <AlertDialogFooter>
              <AlertDialogCancel>Mbyll</AlertDialogCancel>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
      ) : (
        ""
      )}
    </div>
  );
}

export const columns: ColumnDef<Entity>[] = [
  {
    accessorKey: "numberOfBIll",
    header: "ID e faturës",
    cell: ({ row }) => (
      <div className="px-3.5 py-2 capitalize rounded-lg my-2 text-center">
        {row.getValue("numberOfBIll")}
      </div>
    ),
  },
  {
    accessorKey: "value",
    header: "Vlera",
    cell: ({ row }) => (
      <div className="px-3.5 py-2 capitalize rounded-lg my-2 text-center">
        {row.getValue("value")} Lekë
      </div>
    ),
  },
  {
    accessorKey: "paid",
    header: "Paguar",
    cell: ({ row }) => (
      <div className="px-3.5 py-2 capitalize rounded-lg my-2 text-center">
        {row.getValue("paid")} Lekë
      </div>
    ),
  },
  {
    accessorKey: "remaining",
    header: "Mbetur",
    cell: ({ row }) => (
      <div className="px-3.5 py-2 capitalize rounded-lg my-2 text-center">
        {row.getValue("remaining")} Lekë
      </div>
    ),
  },
  {
    accessorKey: "debt",
    header: "Interesi i vonuar",
    cell: ({ row }) => {
      const formattedNumber = parseFloat(row.getValue("debt"));
      return (
        <div className="px-3.5 py-2 capitalize rounded-lg my-2 text-center">
        {row.getValue("debt")}
          <span className="font-semibold">Lekë</span>
        </div>
      );
    },
  },
  {
    accessorKey: "period",
    header: "Periudha (DD/MM/YYYY)",
    cell: ({ row }) => (
      <div className="px-3.5 py-2 capitalize rounded-lg my-2 text-center">
        {row.getValue("period")}
      </div>
    ),
  },
  {
    accessorKey: "billSTatus",
    header: "Statusi i Pagesave",
    cell: ({ row }) => (
      <div
        className={cn(
          "px-3.5 py-2 rounded-lg my-2 text-center max-w-md",
          row.getValue("billSTatus") === "PAYED"
            ? "bg-green-200 text-green-700 font-semibold"
            : row.getValue("billSTatus") === "TOPAY"
            ? "bg-red-200 text-red-950 font-semibold"
            : "bg-orange-200 text-orange-900 font-semibold"
        )}
      >
        {row.getValue("billSTatus") === "PAYED"
          ? "Paguar"
          : row.getValue("billSTatus") === "TOPAY"
          ? "Për tu paguar"
          : "Paguar pjesërisht"}
      </div>
    ),
  },
  {
    accessorKey: "id",
    header: "",
    cell: ({ row }) => <BillCell entity={row.original} row={row} />,
  },
];

export function EntityBillTable(props: any) {
  const { billsData, generateBill, entityTaxesData } = props;
  const [sorting, setSorting] = React.useState<SortingState>([]);
  let [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );
  const [columnVisibility, setColumnVisibility] =
    React.useState<VisibilityState>({});
  const [rowSelection, setRowSelection] = React.useState({});

  const table = useReactTable({
    data: billsData?.companyBills ? billsData.companyBills : undefined,
    columns,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      rowSelection,
    },
  });

  const { data: entityByIdData } = useQuery(ENTITY_BY_ID_QUERY, {
    fetchPolicy: "cache-and-network",
    variables: {
      in: {
        companyId: id,
        userId: null,
      },
    },
  });
  return (
    <div className="w-full">
      <div className="flex items-center py-4">
        <p className="font-medium text-lg">Historiku i faturave</p>
        <div className="ml-auto">
          <AlertDialog>
            <AlertDialogTrigger asChild>
              <Button
                variant="outline"
                className="mr-3 bg-orange-300 hover:bg-orange-200 text-white"
              >
                Njoftim Vlerësimi
              </Button>
            </AlertDialogTrigger>
            <AlertDialogContent className="min-w-[80svw] min-h-[90svh] flex flex-col">
              <AlertDialogHeader className="flex-none">
                <AlertDialogTitle>
                  Njoftim Vlerësimi për detyrime tatimore Bashkia Mat
                </AlertDialogTitle>
                <AlertDialogDescription className="flex flex-col gap-1"></AlertDialogDescription>
              </AlertDialogHeader>
              <PDFViewer className="w-full flex-1">
                <BillAssessment billsData={billsData?.companyBills[0]} entityTaxesData={entityTaxesData} entityByIdData={entityByIdData?.companyById} />
              </PDFViewer>
              <AlertDialogFooter>
                <AlertDialogCancel>Mbyll</AlertDialogCancel>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
          {getUserRole() !== undefined &&
            getUserRole() !== "FINANCE_READ" &&
            billsData?.companyBills.length === 0 && (
              <Button variant="outline" className="mr-2" onClick={generateBill}>
                Gjenero faturë <FileSignature className="ml-2 h-4 w-4" />
              </Button>
            )}
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="outline">
                Filtro të dhënat <ChevronDown className="ml-2 h-4 w-4" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              {table
                .getAllColumns()
                .filter((column) => column.getCanHide())
                .map((column) => {
                  return (
                    <DropdownMenuCheckboxItem
                      key={column.id}
                      className="capitalize"
                      checked={column.getIsVisible()}
                      onCheckedChange={(value) =>
                        column.toggleVisibility(!!value)
                      }
                    >
                      {String(column.columnDef?.header)}
                    </DropdownMenuCheckboxItem>
                  );
                })}
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </div>
      <div className="rounded-md border w-full max-w-[calc(100vw-32px)]">
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead key={header.id} className="text-center">
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {billsData?.companyBills ? (
              table.getRowModel().rows.map((row: any) => (
                <TableRow
                  key={row.id}
                  data-state={row.getIsSelected() && "selected"}
                >
                  {row.getVisibleCells().map((cell: any) => (
                    <TableCell key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns?.length}
                  className="h-24 text-center"
                >
                  Asnjë rezultat.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
    </div>
  );
}
