import React from "react";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../components/ui/popover";
import { Button } from "../../components/ui/button";
import { Checkbox } from "../../components/ui/checkbox";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../../components/ui/dropdown-menu";
import { Input } from "../../components/ui/input";
import { Label } from "../../components/ui/label";
import { RadioGroup, RadioGroupItem } from "../../components/ui/radio-group";
import { ScrollArea } from "../../components/ui/scroll-area";
import { Separator } from "../../components/ui/separator";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "../../components/ui/command";
import { cn } from "../../lib/utils";
import { CheckIcon, CarrotIcon, ChevronsUpDown, Trash2 } from "lucide-react";

const AddEntityForm = (props: any) => {
  const {
    handleAddData,
    categoriesData,
    checkedStates,
    handleCheckboxChange,
    vehicles,
    getVehicleType,
    businessType,
    setBusinessType,
    companyEnvironmentCode,
    setCompanyEnvironmentCode,
    taxCodification,
    setTaxCodification,
    paymentStatus,
    setPaymentStatus,
    handleCountChange,
    companyStatus,
    setCompanyStatus,
    selectedCategory,
    setSelectedCategory,
    handleAddTables,
    handleChangeTable,
    handleRemoveTables,
    tables,
  } = props;
  const [open, setOpen] = React.useState(false);
  const [searchText, setSearchText] = React.useState("");

  const filteredCategories = categoriesData.categories.filter((category: any) =>
    category.categoryName.toLowerCase().includes(searchText.toLowerCase())
  );

  return (
    <>
      <div className="w-full flex items-center justify-between gap-4">
        <div className="w-[50%] flex flex-col gap-2">
          <label htmlFor="companyName" className="font-semibold">
            Emri subjektit:
          </label>
          <Input
            type="text"
            id="companyName"
            name="companyName"
            onChange={(e) => handleAddData(e)}
            required
          />
        </div>
        <div className="w-[50%] flex flex-col gap-2">
          <label htmlFor="nipt" className="font-semibold">
            NIPT:
          </label>
          <Input
            type="text"
            id="nipt"
            name="nipt"
            onChange={(e) => handleAddData(e)}
            required
          />
        </div>
      </div>

      <div className="w-full flex items-center justify-between gap-4">
        <div className="w-[50%] flex flex-col gap-2">
          <label htmlFor="phone" className="font-semibold">
            Nr. Tel:
          </label>
          <Input
            type="string"
            id="phone"
            name="phone"
            onChange={(e) => handleAddData(e)}
            required
          />
        </div>
        <div className="w-[50%] flex flex-col gap-2">
          <label htmlFor="amountToPay" className="font-semibold">
            Kategoritë:
          </label>
          <Popover open={open} onOpenChange={setOpen}>
            <PopoverTrigger asChild>
              <Button
                variant="outline"
                role="combobox"
                aria-expanded={open}
                className="w-full justify-between"
              >
                {selectedCategory
                  ? categoriesData.categories.find(
                      (category: any) => category.id === selectedCategory
                    )?.categoryName
                  : "Zgjidh kategorinë..."}
                <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
              </Button>
            </PopoverTrigger>
            <PopoverContent className="w-[350px] p-0">
              <Command className="w-full">
                <Input
                  placeholder="Kërko kategori..."
                  className="h-9 border-0 active:border-0"
                  onChange={(e) => setSearchText(e.target.value)}
                />
                <CommandEmpty>Nuk u gjet kategori.</CommandEmpty>
                <CommandGroup>
                  <ScrollArea className="h-64 w-full rounded-md border">
                    {filteredCategories &&
                      filteredCategories.map((category: any) => (
                        <CommandItem
                          key={category.id}
                          value={category.id}
                          onSelect={(currentValue) => {
                            setSelectedCategory(
                              currentValue === selectedCategory
                                ? ""
                                : currentValue
                            );
                            setOpen(false);
                          }}
                        >
                          {category.categoryName}
                          <CheckIcon
                            className={cn(
                              "ml-auto h-4 w-4",
                              selectedCategory === category.id
                                ? "opacity-100"
                                : "opacity-0"
                            )}
                          />
                        </CommandItem>
                      ))}
                  </ScrollArea>
                </CommandGroup>
              </Command>
            </PopoverContent>
          </Popover>
        </div>
      </div>
      <div className="w-full flex items-center justify-between gap-4">
        <div className="w-[50%] flex flex-col gap-2">
          <label htmlFor="buildingSurface" className="font-semibold">
            Sipërfaqja e ndërtesës:
          </label>
          <Input
            type="number"
            min={0}
            id="buildingSurface"
            name="buildingSurface"
            onChange={(e) => handleAddData(e)}
            required
          />
        </div>
        <div className="w-[50%] flex flex-col gap-2">
          <label htmlFor="comercialSurface" className="font-semibold">
            Sipërfaqja Bujqësi/Blegtori:
          </label>
          <Input
            type="number"
            min={0}
            id="comercialSurface"
            name="comercialSurface"
            onChange={(e) => handleAddData(e)}
            required
          />
        </div>
      </div>
      <div className="w-full flex items-center justify-between gap-4">
        <div className="w-[50%] flex flex-col gap-2">
          <label htmlFor="industrialSurface" className="font-semibold">
            Sipërfaqja magazinë,prodhim:
          </label>
          <Input
            type="number"
            min={0}
            id="industrialSurface"
            name="industrialSurface"
            onChange={(e) => handleAddData(e)}
            required
          />
        </div>
        <div className="w-[50%] flex flex-col gap-2">
          <label htmlFor="landSurface" className="font-semibold">
            Sipërfaqja e truallit:
          </label>
          <Input
            type="number"
            min={0}
            id="landSurface"
            name="landSurface"
            onChange={(e) => handleAddData(e)}
            required
          />
        </div>
      </div>
      <div className="w-full flex items-center justify-between gap-4">
        <div className="w-[50%] flex flex-col gap-2">
          <label htmlFor="coveredParkingSurface" className="font-semibold">
            Taksa e parkimit të mbyllur:
          </label>
          <Input
            type="number"
            min={0}
            id="coveredParkingSurface"
            name="coveredParkingSurface"
            onChange={(e) => handleAddData(e)}
            required
          />
        </div>
        <div className="w-[50%] flex flex-col gap-2">
          <label htmlFor="publicSurface" className="font-semibold">
            Sipërfaqja publike:
          </label>
          <Input
            type="number"
            min={0}
            id="publicSurface"
            name="publicSurface"
            onChange={(e) => handleAddData(e)}
            required
          />
        </div>
      </div>
      <div className="w-full text-center">
        <Button
          onClick={handleAddTables}
          type="button"
          className="w-full bg-amber-200 text-black hover:bg-amber-100"
        >
          Shto Tabelë
        </Button>
      </div>
      <div className="w-full flex flex-col gap-4">
        {tables &&
          tables.map((table: any, index: any) => (
            <>
              <div
                key={index}
                className="w-full flex flex-col gap-4 border-2 p-2 rounded-md border-amber-200"
              >
                <div className="w-full flex items-center justify-between gap-4">
                  <div className="w-[40%] flex flex-col gap-2">
                    Llojet e tabelave:
                  </div>
                  <div className="w-[60%] flex flex-col gap-2">
                    <DropdownMenu>
                      <DropdownMenuTrigger asChild>
                        <Button variant="outline">
                          tabela e zgjedhur: {table.tableCode || ""}
                        </Button>
                      </DropdownMenuTrigger>
                      <DropdownMenuContent className="max-w-[300px]">
                        <DropdownMenuSeparator />
                        <DropdownMenuRadioGroup
                          value={table.tableCode || ""}
                          onValueChange={(value) =>
                            handleChangeTable(
                              index,
                              "tableCode",
                              parseInt(value)
                            )
                          }
                        >
                          <DropdownMenuRadioItem
                            value="1"
                            className={`border-b-2 border-amber-200 text-sm ${
                              table.tableCode === 1
                                ? "font-semibold"
                                : "font-normal"
                            }`}
                          >
                            Taksë tabele për qëllime identifikimi, të trupëzuara
                            ose jo, brënda territorit ku zhvillohet veprimtaria
                            e bisnesit dhe që nuk përdoret për të reklamuar
                            veprimtaritë e të tretëve.
                          </DropdownMenuRadioItem>
                          <DropdownMenuRadioItem
                            value="2"
                            className={`border-b-2 border-amber-200 text-sm ${
                              table.tableCode === 2
                                ? "font-semibold"
                                : "font-normal"
                            }`}
                          >
                            Tabela për qëllime identifikimi jashtë territorit ku
                            zhvillohet veprimtaria e biznesit në formën dhe
                            përmasat e tabelave për orientim.
                          </DropdownMenuRadioItem>
                          <DropdownMenuRadioItem
                            value="3"
                            className={`border-b-2 border-amber-200 text-sm ${
                              table.tableCode === 3
                                ? "font-semibold"
                                : "font-normal"
                            }`}
                          >
                            Tabelë për qëllime reklamimi e lëvizshme e
                            palëvizshme deri në tavanin maksimal 35m2.
                          </DropdownMenuRadioItem>
                          <DropdownMenuRadioItem
                            value="4"
                            className={`text-sm ${
                              table.tableCode === 4
                                ? "font-semibold"
                                : "font-normal"
                            }`}
                          >
                            Tabela në funksion të ekspozimeve të ndryshme të
                            hapura, panaire, spektakle, stenda reklamuese,
                            banderola etj.
                          </DropdownMenuRadioItem>
                        </DropdownMenuRadioGroup>
                      </DropdownMenuContent>
                    </DropdownMenu>
                  </div>
                </div>
                {table.tableCode === 1 || table.tableCode === 3 ? (
                  <div className="w-full flex items-center justify-between gap-4">
                    <div className="w-[40%] flex flex-col gap-2">
                      Cilësimet e ndricimit:
                    </div>
                    <div className="w-[60%] flex flex-col gap-2">
                      <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                          <Button variant="outline">
                            Cilësimi: {table.lightingType.toString() || ""}
                          </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent className="max-w-[300px]">
                          <DropdownMenuSeparator />
                          <DropdownMenuRadioGroup
                            value={table.lightingType || ""}
                            onValueChange={(value) =>
                              handleChangeTable(
                                index,
                                "lightingType",
                                parseInt(value)
                              )
                            }
                          >
                            {table.tableCode === 1 ? (
                              <>
                                <DropdownMenuRadioItem
                                  value="0"
                                  className={`border-b-2 border-amber-200 text-sm ${
                                    table.lightingType === 0
                                      ? "font-semibold"
                                      : "font-normal"
                                  }`}
                                >
                                  Pa ndricim
                                </DropdownMenuRadioItem>
                                <DropdownMenuRadioItem
                                  value="1"
                                  className={`border-b-2 border-amber-200 text-sm ${
                                    table.lightingType === 1
                                      ? "font-semibold"
                                      : "font-normal"
                                  }`}
                                >
                                  Me ndricim të thjeshtë
                                </DropdownMenuRadioItem>
                              </>
                            ) : table.tableCode === 3 ? (
                              <>
                                <DropdownMenuRadioItem
                                  value="0"
                                  className={`border-b-2 border-amber-200 text-sm ${
                                    table.lightingType === 0
                                      ? "font-semibold"
                                      : "font-normal"
                                  }`}
                                >
                                  E thjeshtë (me/pa ndricim)
                                </DropdownMenuRadioItem>
                              </>
                            ) : (
                              ""
                            )}
                            <DropdownMenuRadioItem
                              value="2"
                              className={`text-sm ${
                                table.lightingType === 2
                                  ? "font-semibold"
                                  : "font-normal"
                              }`}
                            >
                              Elektronike
                            </DropdownMenuRadioItem>
                          </DropdownMenuRadioGroup>
                        </DropdownMenuContent>
                      </DropdownMenu>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                <div className="w-full flex items-center justify-between gap-4">
                  <div className="w-[40%] flex flex-col gap-2">
                    Siperfaqja e tabelës:
                  </div>
                  <div className="w-[60%] flex flex-col gap-2">
                    <Input
                      type="number"
                      min={0}
                      value={table.tableSurface}
                      onChange={(e) =>
                        handleChangeTable(
                          index,
                          "tableSurface",
                          parseFloat(e.target.value)
                        )
                      }
                    />
                  </div>
                </div>
                <div className="w-full text-center">
                  <Button
                    type="button"
                    variant={"outline"}
                    className="bg-orange-600 hover:bg-orange-500"
                    onClick={() => handleRemoveTables(index)}
                  >
                    <Trash2 size={16} className="text-white" />
                  </Button>
                </div>
              </div>
            </>
          ))}
      </div>
      <div className="w-full text-center">
        <label htmlFor="VehicleCode" className="font-semibold">
          Taksa vjetore e parkimit për automjete:
        </label>
      </div>
      <div className="w-full flex items-center justify-between gap-4">
        <div className="w-[50%] flex flex-col gap-2">
          <label htmlFor="VehicleCode" className="font-semibold">
            Tipet e automjeteve:
          </label>
        </div>
        <div className="w-[50%] flex flex-col gap-2">
          <label htmlFor="taxCodification" className="font-semibold">
            Numri i automjeteve:
          </label>
        </div>
      </div>
      <div className="w-full flex flex-col gap-4">
        {vehicles &&
          vehicles.map((vehicle: any) => (
            <div
              className="w-full flex items-center justify-between gap-4"
              key={vehicle.vehicleCode}
            >
              <div className="w-[50%] flex flex-col gap-2">
                <p>
                  Automjeti:
                  {getVehicleType(vehicle.vehicleCode)}
                </p>
              </div>
              <div className="w-[50%] flex flex-col gap-2">
                <Input
                  type="number"
                  min={0}
                  placeholder="Numri i automjeteve"
                  value={vehicle.count}
                  onChange={(e) =>
                    handleCountChange(vehicle.vehicleCode, e.target.value)
                  }
                />
              </div>
            </div>
          ))}
      </div>
      <div className="flex flex-col gap-2">
        <Label htmlFor="businessType" className="mb-3 font-semibold">
          Lloji i biznesit:
        </Label>
        <RadioGroup
          className="flex flex-row items-center border border-gray-200 rounded-lg px-2"
          name="businessType"
        >
          <div className="flex items-center w-full gap-1">
            <RadioGroupItem
              value="BV"
              id="r1"
              checked={businessType === "BV"}
              onClick={(e: any) => setBusinessType(e.target.value)}
            />
            <Label
              htmlFor="r1"
              className="py-3 w-full text-amber-950 cursor-pointer"
            >
              Biznes i vogël
            </Label>
          </div>
          <div className="flex items-center w-full gap-1">
            <RadioGroupItem
              value="BM"
              id="r2"
              checked={businessType === "BM"}
              onClick={(e: any) => setBusinessType(e.target.value)}
            />
            <Label
              htmlFor="r2"
              className="py-3 w-full text-amber-950 cursor-pointer"
            >
              Biznes i madh
            </Label>
          </div>
          <div className="flex items-center w-full gap-1">
            <RadioGroupItem
              value="VIP"
              id="r3"
              checked={businessType === "VIP"}
              onClick={(e: any) => setBusinessType(e.target.value)}
            />
            <Label
              htmlFor="r3"
              className="py-3 w-full text-amber-950 cursor-pointer"
            >
              Biznes VIP
            </Label>
          </div>
        </RadioGroup>
      </div>
      {/* <div className="flex flex-col gap-2">
        <Label htmlFor="companyEnvironmentCode" className="mb-3 font-semibold">
          Taksa e ndikimit në mjedis:
        </Label>
        <RadioGroup
          className="flex flex-row items-center border border-gray-200 rounded-lg px-2"
          name="companyEnvironmentCode"
        >
          <div className="flex items-center w-full gap-1">
            <RadioGroupItem
              value="1"
              id="env1"
              checked={companyEnvironmentCode === "1"}
              onClick={(e: any) => setCompanyEnvironmentCode(e.target.value)}
            />
            <Label
              htmlFor="env1"
              className="py-3 w-full text-amber-950 cursor-pointer"
            >
              1000 lek/vit
            </Label>
          </div>
          <div className="flex items-center w-full gap-1">
            <RadioGroupItem
              value="2"
              id="env2"
              checked={companyEnvironmentCode === "2"}
              onClick={(e: any) => setCompanyEnvironmentCode(e.target.value)}
            />
            <Label
              htmlFor="env2"
              className="py-3 w-full text-amber-950 cursor-pointer"
            >
              2000/leke/vit
            </Label>
          </div>
          <div className="flex items-center w-full gap-1">
            <RadioGroupItem
              value="3"
              id="env3"
              checked={companyEnvironmentCode === "3"}
              onClick={(e: any) => setCompanyEnvironmentCode(e.target.value)}
            />
            <Label
              htmlFor="env3"
              className="py-3 w-full text-amber-950 cursor-pointer"
            >
              50000 leke/MW
            </Label>
          </div>
        </RadioGroup>
      </div> */}
      <div className="flex flex-col gap-2">
        <Label htmlFor="businessType" className="mb-3 font-semibold">
          Tarifë shkarkimi ujra të përdor:
        </Label>
        <RadioGroup
          className="flex flex-row items-center border border-gray-200 rounded-lg px-2"
          name="taxCodification"
        >
          <div className="flex items-center w-full gap-1">
            <RadioGroupItem
              value="SF"
              id="tax1"
              checked={taxCodification === "SF"}
              onClick={(e: any) => setTaxCodification(e.target.value)}
            />
            <Label
              htmlFor="tax1"
              className="py-3 w-full text-amber-950 cursor-pointer"
            >
              Subjekt Fizik
            </Label>
          </div>
          <div className="flex items-center w-full gap-1">
            <RadioGroupItem
              value="SJ"
              id="tax2"
              checked={taxCodification === "SJ"}
              onClick={(e: any) => setTaxCodification(e.target.value)}
            />
            <Label
              htmlFor="tax2"
              className="py-3 w-full text-amber-950 cursor-pointer"
            >
              Subjekt Juridik
            </Label>
          </div>
          <div className="flex items-center w-full gap-1">
            <RadioGroupItem
              value="ISH"
              id="tax3"
              checked={taxCodification === "ISH"}
              onClick={(e: any) => setTaxCodification(e.target.value)}
            />
            <Label
              htmlFor="tax3"
              className="py-3 w-full text-amber-950 cursor-pointer"
            >
              Institucion Shteteror
            </Label>
          </div>
        </RadioGroup>
      </div>
      <div className="flex flex-col gap-2">
        <Label
          htmlFor="paymentStatus"
          className="mb-3 cursor-pointer font-semibold"
        >
          Statusi i pagesës:
        </Label>
        <RadioGroup
          className="flex flex-row items-center border border-gray-200 rounded-lg px-2"
          name="paymentStatus"
        >
          <div className="flex items-center w-full gap-1">
            <RadioGroupItem
              value="PAPAGUAR"
              id="p1"
              checked={paymentStatus === "PAPAGUAR"}
              onClick={(e: any) => setPaymentStatus(e.target.value)}
            />
            <Label
              htmlFor="p1"
              className="py-3 w-full text-amber-950 cursor-pointer"
            >
              Pa paguar
            </Label>
          </div>
          <div className="flex items-center w-full gap-1">
            <RadioGroupItem
              value="PAGUAR"
              id="p2"
              checked={paymentStatus === "PAGUAR"}
              onClick={(e: any) => setPaymentStatus(e.target.value)}
            />
            <Label
              htmlFor="p2"
              className="py-3 w-full text-amber-950 cursor-pointer"
            >
              Paguar
            </Label>
          </div>
        </RadioGroup>
      </div>
      <div className="flex flex-col gap-2">
        <Label
          htmlFor="companyStatus"
          className="mb-3 cursor-pointer font-semibold"
        >
          Statusi i kompanisë:
        </Label>
        <RadioGroup
          className="flex flex-row items-center border border-gray-200 rounded-lg px-2"
          name="companyStatus"
        >
          <div className="flex items-center w-full gap-1">
            <RadioGroupItem
              value="AKTIV"
              id="a1"
              checked={companyStatus === "AKTIV"}
              onClick={(e: any) => setCompanyStatus(e.target.value)}
            />
            <Label
              htmlFor="a1"
              className="py-3 w-full text-amber-950 cursor-pointer"
            >
              Aktiv
            </Label>
          </div>
          <div className="flex items-center w-full gap-1">
            <RadioGroupItem
              value="PASIV"
              id="a2"
              checked={companyStatus === "PASIV"}
              onClick={(e: any) => setCompanyStatus(e.target.value)}
            />
            <Label
              htmlFor="a2"
              className="py-3 w-full text-amber-950 cursor-pointer"
            >
              Pasiv
            </Label>
          </div>
        </RadioGroup>
      </div>
    </>
  );
};

export default AddEntityForm;
